import React, { useRef, useEffect, useState } from 'react';
import {
    PayPalScriptProvider,
    PayPalButtons, PayPalHostedField, PayPalHostedFieldsProvider,
} from "@paypal/react-paypal-js"
import LockerImage from './images/locker.png'
import useSWR from 'swr'
import {
    createOrder,
    onApprove,
    createApplePayOrder,
    completeApplePayOrder,
    successRedirectPath
} from './actions'
import SubmitPayment from "./SubmitPayment";
import cn from "classnames";
import style from './PayPal.module.scss'

const PayPal = (props) => {
    const { orderId, participantId, fetcher, cents, currency } = props
    const { data, error, isLoading } = useSWR('/api/orders/paypal_token.json',
        () => fetcher('/api/orders/paypal_token.json', {order_id: orderId}),
        { revalidateOnFocus: false, revalidateOnReconnect: false });

    const clientId = process.env.PAY_PAL_CLIENT
    const cardHolderName = useRef(null);
    const country = useRef(null);
    const zip = useRef(null);
    const [applePayEligible, setApplePayEligible] = useState(false);

    const availableCountries = ['AT', 'AU', 'BE', 'BG', 'BR', 'CA', 'CH', 'CY', 'CZ', 'DE', 'DK', 'EE', 'ES', 'FI', 'FR', 'GB', 'GR', 'HK', 'HU', 'IE', 'IN', 'IT', 'JP', 'LT', 'LU', 'LV', 'MT', 'MY', 'NL', 'NO', 'NZ', 'PL', 'PT', 'RO', 'SE', 'SG', 'SI', 'SK', 'US']

    useEffect(() => {
        const checkApplePay = () => {
            const waitForPayPal = setInterval(() => {
                if (window.paypal && paypal.Applepay) {
                    clearInterval(waitForPayPal);

                    if (window.ApplePaySession && ApplePaySession.canMakePayments()) {
                        const applepay = paypal.Applepay();
                        applepay.config()
                            .then((config) => {
                                if (config.isEligible) {
                                    setApplePayEligible(true);
                                }
                            })
                            .catch((error) => console.error("Error fetching Apple Pay configuration:", error));
                    }
                }
            }, 500);

            setTimeout(() => clearInterval(waitForPayPal), 10000);
        };

        checkApplePay();
    }, []);

    useEffect(() => {
        const applePayButton = document.getElementById("applepay_button");

        if (applePayButton) {
            applePayButton.addEventListener("click", handleApplePayClicked);

            return () => {
                applePayButton.removeEventListener("click", handleApplePayClicked);
            };
        }
    }, [applePayEligible]);

    const handleApplePayClicked = () => {
        console.log("Apple Pay button clicked");
        const applepay = paypal.Applepay();
        const paymentRequest = {
            countryCode: "DE",
            currencyCode: currency,
            requiredBillingContactFields: ["postalAddress"],
            total: {
                label: "ZHF GmbH",
                amount: (cents / 100).toFixed(1),
            },
            supportedNetworks: ["visa", "masterCard", "amex", "discover"],
            merchantCapabilities: ["supports3DS", "supportsCredit", "supportsDebit"],
        };

        const session = new ApplePaySession(4, paymentRequest);

        session.onvalidatemerchant = (event) => {
            console.log("Validating merchant...");
            applepay.validateMerchant({ validationUrl: event.validationURL, displayName: "ZHF GmbH" })
                .then(validateResult => {
                    console.log("Merchant validated successfully");
                    session.completeMerchantValidation(validateResult.merchantSession);
                })
                .catch((error) => {
                    console.error("Merchant validation failed:", error);
                    session.abort();
                });
        };

        session.onpaymentauthorized = (event) => {
            console.log("Payment authorized...");

            createApplePayOrder(orderId)
                .then((createdOrderId) => {
                    if (!createdOrderId) {
                        throw new Error("Failed to create Apple Pay order");
                    }
                    console.log("Order created successfully:", createdOrderId);

                    // Подтверждаем заказ через Apple Pay
                    return applepay.confirmOrder({
                        orderId: String(createdOrderId),
                        token: event.payment.token,
                        billingContact: event.payment.billingContact,
                    });
                })
                .then(() => {
                    console.log("Apple Pay confirmation successful");

                    // Завершаем заказ
                    return completeApplePayOrder(orderId, participantId);
                })
                .then((isCompleted) => {
                    if (!isCompleted) {
                        throw new Error("Failed to complete Apple Pay order");
                    }
                    console.log("Payment completed successfully");
                    session.completePayment(ApplePaySession.STATUS_SUCCESS);

                    return window.location = successRedirectPath(orderId, participantId);
                })
                .catch((error) => {
                    console.error("Payment failed:", error);
                    session.completePayment(ApplePaySession.STATUS_FAILURE);
                });
        };

        console.log("Starting Apple Pay session...");
        session.begin();
    };

    if (error) return <div>Token load error, please refresh the page or try later</div>
    if (isLoading) return <div>Loading...</div>

    return (
        <>
            <div id="paypal-button-container" className="paypal-button-container"></div>

            <PayPalScriptProvider
                options={{
                    clientId: clientId,
                    components: "buttons,hosted-fields,applepay",
                    dataClientToken: data.client_token,
                    intent: "capture",
                }}
            >
                <PayPalButtons
                    style={{ label: 'buynow' }}
                    createOrder={() => createOrder(orderId)}
                    onApprove={() => onApprove(orderId, participantId)}
                />

                {applePayEligible && (
                    <div id="applepay-container" className="mt-3">
                        <apple-pay-button
                            id="applepay_button"
                            buttonstyle="black"
                            type="plain"
                            locale="en"
                            onClick={handleApplePayClicked}
                        ></apple-pay-button>
                    </div>
                )}

                {
                    !process.env.STRIPE_PUBLIC_KEY && (
                        <>
                            <div className={cn(style.separator, 'text-secondary mt-3 mb-3')} >or</div>
                            <PayPalHostedFieldsProvider
                                createOrder={() => createOrder(orderId)}
                            >
                                <div className="row">
                                    <div className="col-12">
                                        <PayPalHostedField
                                            id="card-number"
                                            className={cn(style.control, 'form-control rounded-3 string optional mb-3')}
                                            hostedFieldType="number"
                                            options={{
                                                selector: "#card-number",
                                                placeholder: "1234 1234 1234 1234",
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6">
                                        <PayPalHostedField
                                            id="expiration-date"
                                            className={cn(style.control, 'form-control rounded-3 string optional mb-3')}
                                            hostedFieldType="expirationDate"
                                            options={{
                                                selector: "#expiration-date",
                                                placeholder: "MM/YY",
                                            }}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <PayPalHostedField
                                            id="cvv"
                                            className={cn(style.control, 'form-control rounded-3 string optional mb-3')}
                                            hostedFieldType="cvv"
                                            options={{
                                                selector: "#cvv",
                                                placeholder: "CVV"
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <input type="text" id="card-holder-name" className={cn(style.control, 'form-control rounded-3 string optional mb-3')} autoComplete="off" placeholder="Name on card" ref={cardHolderName} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <select id="card-billing-address-country" className="form-select rounded-3 string optional mb-3" placeholder="Country" defaultValue="US" ref={country}>
                                            {availableCountries.map(country =>
                                                <option key={country} value={country}>{country}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-6">
                                        <input type="text" id="card-billing-address-zip" className={cn(style.control, 'form-control rounded-3 string optional mb-3')} autoComplete="off" placeholder="Zip code" ref={zip} />
                                    </div>
                                </div>
                                <SubmitPayment cardHolderName={cardHolderName} zip={zip} country={country} {...props} />
                            </PayPalHostedFieldsProvider>
                            <div className='d-flex flex-row justify-content-center align-items-center'>
                                <img src={LockerImage} width='15px' height='20px'></img>
                                <div className='to-win mt-1 ms-2'>Secure payment</div>
                            </div>
                        </>
                    )
                }
            </PayPalScriptProvider >
        </>
    );
}

export default PayPal